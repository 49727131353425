import COLORS from 'COLORS'
import turfBbox from '@turf/bbox'
import {
  clusterLayerConfig,
  clusterCountLayerConfig,
  styleConfigs,
  createdObjectConfig,
  oldGeomConfig
} from '@/config/map/layer-styles'
// import { palettes } from '@/config/map/colors'
import { paintSettings, layoutSettings } from '@/config/map/layer-setting-names'
import { filterObjectByKeys } from './data-processing'
import { getTheme } from '@/helpers/theme'
import { jsonToGeojson } from '@/helpers/json'
import { debounce } from './debounce'
import {
  additionalLayerNames,
  clusterLayerNames
} from '@/config/map/additional-layer-names'

const DIVIDED_PI = Math.PI / 180
const R = 6371e3 // Earth's radius in meters

const baselayerSymbolId = {
  Basic: 'bridge_major'
}

export const getFirstSymbolId = mapgl => {
  if (!mapgl || mapgl._removed) return

  const layers = mapgl.getStyle()?.layers

  if (!layers) return

  const symbolIndex = layers.findIndex(
    e => e.type === 'symbol' && e.id.includes('label')
  )

  if (symbolIndex !== -1) {
    return layers[symbolIndex].id
  }

  return layers[layers.length].id
}

export const getFirstCustomLayerId = mapgl => {
  const { layers, name } = mapgl.getStyle()
  const beforeFirstSymbolId = baselayerSymbolId[name]
  const index = layers.findIndex(l => l.id === beforeFirstSymbolId) + 1

  return layers[index]?.id
}

export const getFirstIdAfterLayersGroup = (mapgl, id) => {
  const allMapLayers = mapgl.getStyle().layers
  const currentLayersGroup = allMapLayers.filter(
    l =>
      l.id === id || (l.id.includes(`${id}_`) && l.id.indexOf(`${id}_`) === 0)
  )
  const last = currentLayersGroup[currentLayersGroup.length - 1].id
  const index = allMapLayers.findIndex(l => l.id === last)

  return allMapLayers[index + 1]?.id
}

export const loadCustomIcons = (mapgl, array) => {
  const MAP_ICON_SIZE = 64

  if (!mapgl || mapgl._removed) return

  const imageCssText = 'background-position: 50%, 50%; background-size: 100%;'

  return Promise.all(
    array.map(
      item =>
        new Promise(resolve => {
          const image = new Image(MAP_ICON_SIZE, MAP_ICON_SIZE)

          image.crossOrigin = 'Anonymous'
          image.style.cssText = imageCssText

          image.addEventListener('load', () => {
            if (mapgl && !mapgl._removed && !mapgl.hasImage(item.name)) {
              mapgl.addImage(item.name, image, { sdf: item.sdf })
            }

            resolve()
          })

          image.src = item.url
        })
    )
  )
}

export const flyToGeom = debounce(
  ({
    mapgl,
    geom,
    bbox,
    coords,
    speed = 5,
    padding = 64,
    zoom: customZoom = 17,
    animation = true,
    ...params
  }) => {
    const FLY_TO_LAYER_NAME = 'animation-stroke'
    const FLY_TO_SOURCE_NAME = 'animation-source'
    const FLY_TO_ANIMATION_LENGTH = 2000

    if (!mapgl || mapgl._removed) return

    // if (mapgl.getLayer(FLY_TO_LAYER_NAME)) return

    if (!geom && !coords && !bbox?.some(Boolean) && !customZoom) return

    //  ← — — — — — — — — — — — — {{ 🗲 }} — — — — — — — — — — — — → //

    const removeFlyToLayer = () => {
      mapgl.removeLayer(FLY_TO_LAYER_NAME)
      mapgl.removeSource(FLY_TO_SOURCE_NAME)
    }

    const animateFlyToLayer = (mapgl, startTime, widthType, opacityType) => {
      const currentTime = Date.now()
      const elapsedTime = currentTime - startTime

      if (elapsedTime < FLY_TO_ANIMATION_LENGTH) {
        const opacity = Math.abs(Math.sin(elapsedTime / 200))
        const width = 3 + 2 * Math.abs(Math.sin(elapsedTime / 200))

        if (mapgl.getLayer(FLY_TO_LAYER_NAME)) {
          mapgl.setPaintProperty(FLY_TO_LAYER_NAME, opacityType, opacity)
          mapgl.setPaintProperty(FLY_TO_LAYER_NAME, widthType, width)

          requestAnimationFrame(() =>
            animateFlyToLayer(mapgl, startTime, widthType, opacityType)
          )
        }
      } else {
        removeFlyToLayer()
      }
    }

    //  ← — — — — — — — — — — — — {{ 🗲 }} — — — — — — — — — — — — → //

    const { mapColors } = COLORS
    const { pink } = mapColors

    let center, zoom

    if (geom || bbox) {
      if (bbox && bbox.filter(Boolean)?.length < 4) {
        console.log('there is no geom')

        return
      }

      const box = bbox ?? turfBbox(geom)
      const bounds = mapgl.cameraForBounds(box, {
        padding,
        maxZoom: customZoom
      })
      center = bounds.center
      zoom = bounds.zoom
    } else {
      center = coords
      zoom = customZoom
    }

    mapgl.flyTo({
      center,
      zoom,
      speed: 5,
      ...params
    })

    if (!geom || !animation || !geom.type) return

    let layerType = getLayerTypeByGeomType(geom.type)
    layerType = layerType === 'fill' ? 'line' : layerType

    const animatingProps = {
      line: ['line-width', 'line-opacity'],
      circle: ['circle-stroke-width', 'circle-stroke-opacity']
    }

    const layerProps = {
      circle: {
        paint: {
          'circle-radius': 20,
          'circle-opacity': 0.1,
          'circle-color': pink,
          'circle-stroke-color': pink,
          'circle-stroke-width': 4
        }
      },
      line: {
        paint: {
          'line-opacity': 1,
          'line-color': pink,
          'line-width': 1
        },
        layout: {
          'line-cap': 'round',
          'line-join': 'round'
        }
      }
    }

    const sourceProps = {
      type: 'geojson',
      data: {
        type: 'FeatureCollection',
        features: [
          {
            type: 'Feature',
            geometry: {
              type: geom.type,
              coordinates: geom.coordinates
            }
          }
        ]
      }
    }

    if (!mapgl.getSource(FLY_TO_SOURCE_NAME)) {
      mapgl.addSource(FLY_TO_SOURCE_NAME, sourceProps)
    } else {
      mapgl.getSource(FLY_TO_SOURCE_NAME).setData(sourceProps.data)
    }

    if (!mapgl.getLayer(FLY_TO_LAYER_NAME)) {
      mapgl.addLayer({
        id: FLY_TO_LAYER_NAME,
        type: layerType,
        source: FLY_TO_SOURCE_NAME,
        ...layerProps[layerType]
      })

      requestAnimationFrame(() =>
        animateFlyToLayer(mapgl, Date.now(), ...animatingProps[layerType])
      )
    }
  },
  256
)

export const getPolygonCoordsArrayByGeom = geom => {
  const isMultiGeom = geom.type.toLowerCase().includes('multi')

  return isMultiGeom ? geom.coordinates[0][0] : geom.coordinates[0]
}

export const geojsonByGeom = geometry => {
  return {
    type: 'FeatureCollection',
    features: [
      {
        type: 'Feature',
        properties: {},
        geometry
      }
    ]
  }
}

export const featuresToGeojson = features => {
  return {
    type: 'FeatureCollection',
    features
  }
}

export const emptyGeojson = {
  type: 'FeatureCollection',
  features: []
}

export const getColorPropByGeomType = geomType => {
  const type = geomType?.toLowerCase()

  switch (type) {
    case 'polygon':
    case 'multipolygon':
    case 'fill':
      return 'fill-color'
    case 'linestring':
    case 'line':
    case 'multilinestring':
      return 'line-color'
    case 'point':
    case 'circle':
      return 'circle-color'
    default:
      console.warn('wrong geom type')

      return 'circle-color'
  }
}

export const getOpacityPropByGeomType = geomType => {
  const type = geomType?.toLowerCase()
  switch (type) {
    case 'polygon':
    case 'multipolygon':
    case 'fill':
      return 'fill-opacity'
    case 'linestring':
    case 'line':
    case 'multilinestring':
      return 'line-opacity'
    case 'point':
    case 'circle':
      return 'circle-opacity'
    default:
      console.warn('wrong geom type')

      return 'circle-opacity'
  }
}

export const getStrokeColorPropByGeomType = geomType => {
  const type = geomType?.toLowerCase()
  switch (type) {
    case 'fill':
      return 'line-color'
    case 'linestring':
    case 'line':
    case 'multilinestring':
      return null
    case 'point':
    case 'circle':
      return 'circle-stroke-color'
    default:
      console.warn('wrong geom type')

      return 'circle-stroke-color'
  }
}

export const getSizePropByGeomType = geomType => {
  const type = geomType?.toLowerCase()
  switch (type) {
    case 'linestring':
    case 'line':
    case 'multilinestring':
      return 'line-width'
    case 'point':
    case 'circle':
      return 'circle-radius'
    default:
      console.warn(`wrong geom type: ${geomType}`)

      return null
  }
}

export const hasSizeProp = geomType => {
  const type = geomType?.toLowerCase()
  switch (type) {
    case 'linestring':
    case 'line':
    case 'multilinestring':
    case 'point':
    case 'circle':
      return true
    default:
      return false
  }
}

export const getStrokeOpacityPropByGeomType = geomType => {
  const type = geomType?.toLowerCase()
  switch (type) {
    case 'fill':
      return 'line-opacity'
    case 'linestring':
    case 'line':
    case 'multilinestring':
      return null
    default:
      return 'circle-stroke-opacity'
  }
}

export const getStrokeWidthPropByGeomType = geomType => {
  const type = geomType?.toLowerCase()
  switch (type) {
    case 'fill':
      return 'line-width'
    case 'linestring':
    case 'line':
    case 'multilinestring':
      return null
    default:
      return 'circle-stroke-width'
  }
}

export const getIconByGeomType = geomType => {
  const type = String(geomType)?.toLowerCase()

  switch (type) {
    case 'point':
    case 'circle':
    case 'multipoint':
      return 'node-layer'

    case 'polygon':
    case 'multipolygon':
    case 'fill':
      return 'polygon-layer'

    case 'linestring':
    case 'line':
    case 'multilinestring':
      return 'line-layer'

    default: {
      console.warn('Wrong geom_type detected!')

      return 'common-layer'
    }
  }
}

export const getLayerTypeByGeomType = geomType => {
  const type = geomType?.toLowerCase()
  switch (type) {
    case 'point':
    case 'multipoint':
    case 'circle':
      return 'circle'
    case 'polygon':
    case 'multipolygon':
    case 'fill':
      return 'fill'
    case 'linestring':
    case 'line_string':
    case 'multilinestring':
    case 'line':
      return 'line'
    default: {
      console.warn(`type ${geomType} not found`)

      return 'circle'
    }
  }
}

export const getAdditionalLayers = (id, withClusters = true) => {
  const additionalLayers = additionalLayerNames.map(al => `${id}_${al}`)

  if (withClusters) {
    clusterLayerNames.forEach(cl => {
      additionalLayers.push(`${id}_${cl}`)
    })
  }

  return additionalLayers
}

export const createExtrusionRangeValue = (
  field,
  fieldMin,
  fieldMax,
  height
) => {
  if (fieldMin === fieldMax) {
    return height
  } else {
    return [
      'interpolate',
      ['linear'],
      ['get', field],
      fieldMin,
      0,
      fieldMax,
      height
    ]
  }
}

export const objectToFeature = (object, geomField = 'geom') => {
  const coordinates = object[geomField]?.coordinates
  const type = object[geomField]?.type || null
  const properties = { ...object }
  delete properties[geomField]
  const geometry =
    coordinates && type
      ? {
          coordinates,
          type
        }
      : null

  return {
    type: 'Feature',
    properties,
    geometry
  }
}

export const createFeature = (type, coordinates, properties = {}) => {
  const geometry = coordinates
    ? {
        coordinates,
        type
      }
    : null

  return {
    type: 'Feature',
    properties,
    geometry
  }
}

export const pointFeature = (coordinates, properties = {}) => {
  return createFeature('Point', coordinates, properties)
}

export const lineFeature = (coordinates, properties = {}) => {
  return createFeature('LineString', coordinates, properties)
}

export const multiLineFeature = (coordinates, properties = {}) => {
  return createFeature('MultiLineString', coordinates, properties)
}

export const polygonFeature = (coordinates, properties = {}) => {
  return createFeature('Polygon', coordinates, properties)
}

export const getUniqueFeatures = (features, uniquePropName = 'id') => {
  const uniqueIds = new Set()
  const uniqueFeatures = []

  for (const feature of features) {
    const id = feature.properties?.[uniquePropName]

    if (!uniqueIds.has(id)) {
      uniqueIds.add(id)
      uniqueFeatures.push(feature)
    }
  }

  return uniqueFeatures
}

export const createClusterLayer = (mapgl, id, clustersHandlers) => {
  const clustersLayerId = `${id}_clusters`
  const clustersCountLayerId = `${id}_clusters_count`

  if (
    !mapgl ||
    mapgl._removed ||
    mapgl.getLayer(clustersLayerId) ||
    mapgl.getLayer(clustersCountLayerId)
  )
    return

  mapgl.addLayer({
    id: clustersLayerId,
    source: id,
    ...clusterLayerConfig
  })

  mapgl.addLayer({
    id: clustersCountLayerId,
    source: id,
    ...clusterCountLayerConfig
  })

  clustersHandlers[id] = e => {
    const features = mapgl.queryRenderedFeatures(e.point, {
      layers: [`${id}_clusters`]
    })

    if (e.type === 'mousemove') {
      if (features[0].properties.cluster) {
        mapgl.getCanvas().style.cursor = 'pointer'
      } else {
        mapgl.getCanvas().style.cursor = ''
      }

      return
    }

    const clusterId = features[0].properties.cluster_id

    mapgl
      .getSource(id)
      .getClusterExpansionZoom(clusterId)
      .then(zoom => {
        mapgl.easeTo({
          center: features[0].geometry.coordinates,
          zoom: zoom + 2
        })
      })
  }

  mapgl.on('click', `${id}_clusters`, clustersHandlers[id])
  mapgl.on('mousemove', `${id}_clusters`, clustersHandlers[id])
}

export const getDistanceBetweenCoords = (c1, c2, options = {}) => {
  const units = options?.units || 'kilometers'
  const [lon1, lat1] = c1
  const [lon2, lat2] = c2

  const phi1 = lat1 * DIVIDED_PI
  const phi2 = lat2 * DIVIDED_PI
  const deltaPhi = (lat2 - lat1) * DIVIDED_PI
  const deltaLambda = (lon2 - lon1) * DIVIDED_PI

  const a =
    Math.sin(deltaPhi / 2) * Math.sin(deltaPhi / 2) +
    Math.cos(phi1) *
      Math.cos(phi2) *
      Math.sin(deltaLambda / 2) *
      Math.sin(deltaLambda / 2)
  const c = 2 * Math.atan2(Math.sqrt(a), Math.sqrt(1 - a))

  switch (units) {
    case 'meters':
      return R * c
    default:
      return (R * c) / 1000
  }
}

export const getLengthOfLine = (line, options = {}) => {
  const { units, float = 0 } = options
  const lineCoords = line.coordinates ?? line.geometry.coordinates
  let length = 0

  const type = line.geometry?.type || line.type

  if (type.toLowerCase().includes('multi')) {
    lineCoords.forEach(item => {
      length += calculateDistance(item)
    })
  } else {
    length = calculateDistance(lineCoords)
  }

  switch (units) {
    case 'kilometers':
      return +(length / 1000).toFixed(float)
    default:
      return +length.toFixed(float)
  }
}

const calculateDistance = coords => {
  let length = 0
  for (let i = 1; i < coords.length; i++) {
    const [lon1, lat1] = coords[i - 1]
    const [lon2, lat2] = coords[i]

    const phi1 = lat1 * DIVIDED_PI
    const phi2 = lat2 * DIVIDED_PI
    const deltaPhi = (lat2 - lat1) * DIVIDED_PI
    const deltaLambda = (lon2 - lon1) * DIVIDED_PI

    const a =
      Math.sin(deltaPhi / 2) * Math.sin(deltaPhi / 2) +
      Math.cos(phi1) *
        Math.cos(phi2) *
        Math.sin(deltaLambda / 2) *
        Math.sin(deltaLambda / 2)
    const c = 2 * Math.atan2(Math.sqrt(a), Math.sqrt(1 - a))

    length += R * c
  }

  return length
}

// export const prepareHeatmapColor = paletteId => {
//   const palette = [
//     'rgba(0,0,0,0)',
//     ...palettes.find(p => p.id === paletteId).value
//   ]

//   return [
//     'interpolate',
//     ['linear'],
//     ['heatmap-density'],
//     ...palette.reduce((a, c, i) => {
//       a.push(Number((i / palette.length).toFixed(2)))
//       a.push(c)

//       return a
//     }, [])
//   ]
// }

export const getFilterAttr = filter => {
  if (!filter?.length) return null

  // if (op === 'in') {
  //   filter = [
  //     op,
  //     [conversion, value.toLowerCase()],
  //     [conversion, ['downcase', ['get', attribute.value]]]
  //   ]
  // } else {
  //   filter = [op, [conversion, ['get', attribute.value]], [conversion, value]]
  // }

  // datetime
  // filter = [
  //   'all',
  //   ['>=', ['get', attribute.value], start],
  //   ['<=', ['get', attribute.value], end]
  // ]

  // array
  // const conditions = value.map(id => {
  //   return [op, id, ['get', attribute.value]]
  // })
  // filter = ['any', ...conditions]

  const op = filter[0]
  let field
  let value

  if (op === 'in') {
    value = filter[1][1]
    field = filter[2][1][1][1]
  } else if (op === 'all') {
    // for datetime
    value = [filter[1][2], filter[2][2]]
    field = filter[1][1][1]
  } else if (op === 'any') {
    // for multiple select
    value = filter.reduce((acc, curr, index) => {
      if (index > 0) acc.push(curr[1])

      return acc
    }, [])
    field = filter[1][2][1]
  } else {
    value = filter[2][1] === '' ? '' : filter[2][1] ?? 'no_data'
    field = filter[1][1][1]
  }

  return {
    op,
    field,
    value
  }
}

const getToggleCustomLayerData = async (geometry, id) => {
  if (geometry.type === 'FeatureCollection' && geometry.features?.length > 1) {
    return geometry
  }

  const geom = geometry.features?.[0]?.geometry || geometry

  return await jsonToGeojson([{ id, geom }])
}

const toggleCustomCentroid = async ({ mapgl, geom, id, drawCentroid }) => {
  const centroidLayerId = `${id}_centroid`

  if (mapgl.getLayer(centroidLayerId)) mapgl.removeLayer(centroidLayerId)

  if (!geom?.features?.[0]?.properties?.geom_centroid || !drawCentroid) {
    if (mapgl.getSource(centroidLayerId)) {
      mapgl.removeSource(centroidLayerId)
    }

    return
  }

  const data = await getToggleCustomLayerData(
    geom?.features?.[0]?.properties?.geom_centroid,
    centroidLayerId
  )

  if (!mapgl.getSource(centroidLayerId)) {
    mapgl.addSource(centroidLayerId, {
      type: 'geojson',
      data
    })
  } else {
    mapgl.getSource(centroidLayerId).setData(data)
  }

  mapgl.addLayer(
    {
      id: centroidLayerId,
      source: centroidLayerId,
      ...createdObjectConfig.point
    },
    getFirstSymbolId(mapgl)
  )
}

export const toggleCustomLayer = async ({
  mapgl,
  layerId,
  geom,
  alwaysRender,
  drawCentroid,
  onlyCentroid
}) => {
  if (!mapgl || mapgl._removed) return

  const id = `$NEW_${layerId}`
  const borderLayerId = `${id}_border`

  if (mapgl.getLayer(borderLayerId)) mapgl.removeLayer(borderLayerId)

  if (mapgl.getLayer(id) && !alwaysRender) {
    mapgl.removeLayer(id)

    if (mapgl.getSource(id)) {
      mapgl.removeSource(id)
    }
  } else if (geom && !onlyCentroid) {
    const data = await getToggleCustomLayerData(geom, id)
    const geomType = geom?.features?.[0]?.geometry?.type || geom.type
    const type = getLayerTypeByGeomType(geomType)

    const style = Object.values(createdObjectConfig).find(c => c.type === type)

    if (!mapgl.getSource(id)) {
      mapgl.addSource(id, {
        type: 'geojson',
        data
      })
    } else {
      mapgl.getSource(id).setData(data)
    }

    // add custom layer before mapbox draw layers
    const beforeId =
      mapgl.getStyle().layers.find(layer => layer.id.startsWith('gl-draw'))
        ?.id || getFirstSymbolId(mapgl)

    if (!mapgl.getLayer(id)) {
      mapgl.addLayer({ id, type, source: id, ...style }, beforeId)
    }

    if (!['Polygon', 'MultiPolygon'].includes(geomType)) return

    mapgl.addLayer(
      {
        id: borderLayerId,
        source: id,
        ...createdObjectConfig.polygon_border
      },
      beforeId
    )
  }

  await toggleCustomCentroid({ mapgl, geom, id, drawCentroid })
}

export const validateLayerConfigByType = (config, type) => {
  const paint = filterObjectByKeys(
    config.paint || {},
    paintSettings[type],
    true
  )

  const layout = filterObjectByKeys(
    config.layout || {},
    layoutSettings[type],
    true
  )

  return { paint, layout }
}

export function addOnePropPopup({
  mapgl,
  layerId,
  subscribe,
  propertyName,
  propertyHandler
}) {
  if (!['on', 'off'].includes(subscribe)) return

  const POPUP_ID = 'one-prop-popup'
  const mapContainer = document.getElementById('map')

  function trackHoverHandler(e) {
    const $popup = document.getElementById(POPUP_ID)

    const prop = propertyName
      ? e?.features?.[0]?.properties?.[propertyName]
      : null

    if (prop !== undefined) {
      mapgl.getCanvas().style.cursor = 'pointer'

      $popup.innerHTML = propertyHandler ? propertyHandler(prop) : prop

      $popup.style.cssText += `display: block; top: ${
        e.point.y + 16
      }px; left: ${e.point.x + 16}px;`
    } else {
      mapgl.getCanvas().style.cursor = ''
      $popup.style.display = 'none'
    }
  }

  const hndlr = trackHoverHandler.bind({ mapgl })

  createPopup({ id: POPUP_ID, map: mapContainer })

  mapgl[subscribe]('mouseenter', layerId, hndlr)
  mapgl[subscribe]('mouseleave', layerId, hndlr)
}

export const createPopup = ({ id, map }) => {
  if (!map || document.getElementById(id)) return

  const popupElement = document.createElement('span')
  popupElement.id = id

  popupElement.style.cssText = `
      display: none;
      position: absolute;
      z-index: 1000;
      border: 1px solid var(--field-border);
      background: var(--bg-panel-primary);
      font-size: 12px; padding: 0.25rem;
      border-radius: var(--border-radius);
      color: var(--text-primary);
    `
  map.appendChild(popupElement)
}

export const addPointOnTopOfIconLayer = ({
  mapgl,
  sourceId,
  objectsIds,
  idName = 'id'
}) => {
  removePointOnTopOfIconLayer(mapgl)
  const isTileSource = mapgl.getSource(sourceId)?.type === 'vector'

  const pointType = 'circle'

  const topPointConfig = {
    id: 'ICON_TOP_POINT',
    ...oldGeomConfig.circle
  }
  // const selectedObjectLayerId = `${id}_${layerId}`
  const beforeId = getFirstSymbolId(mapgl)

  mapgl.addLayer(
    {
      type: pointType,
      source: sourceId,
      ...(isTileSource ? { 'source-layer': 'default' } : {}),
      filter: ['in', ['get', idName], ['literal', objectsIds]],
      ...topPointConfig
    },
    beforeId
  )
}

export const removePointOnTopOfIconLayer = mapgl => {
  if (!mapgl || mapgl._removed || !mapgl?.getLayer('ICON_TOP_POINT')) return
  mapgl.removeLayer('ICON_TOP_POINT')
}

export const toggleOldGeomLayer = async ({ mapgl, geom }) => {
  if (!mapgl || mapgl._removed) return
  const id = 'OLD_GEOM'
  const borderLayerId = `${id}_border`

  if (mapgl.getLayer(borderLayerId)) mapgl.removeLayer(borderLayerId)

  if (mapgl.getLayer(id)) {
    mapgl.removeLayer(id)

    if (mapgl.getSource(id)) {
      mapgl.removeSource(id)
    }
  }

  if (geom) {
    const data = await jsonToGeojson([{ id, geom }])
    const geomType = geom.type.toLowerCase()
    const type = getLayerTypeByGeomType(geomType)

    const config = {
      id,
      ...oldGeomConfig[type]
    }

    if (!mapgl.getSource(id)) {
      mapgl.addSource(id, {
        type: 'geojson',
        data
      })
    } else {
      mapgl.getSource(id).setData(data)
    }

    // add custom layer before mapbox draw layers
    const beforeId =
      mapgl.getStyle().layers.find(layer => layer.id.startsWith('gl-draw'))
        ?.id || getFirstSymbolId(mapgl)

    if (!mapgl.getLayer(id)) {
      mapgl.addLayer({ id, type, source: id, ...config }, beforeId)
    }

    if (!['Polygon', 'MultiPolygon'].includes(geom.type)) return

    mapgl.addLayer(
      {
        id: borderLayerId,
        source: id,
        type: 'line',
        ...styleConfigs.line.main.style,
        paint: {
          ...styleConfigs.line.main.style.paint,
          'line-color': COLORS.mapColors.gray
        }
      },
      beforeId
    )
  }
}

// Get layer style: circle, symbol, polygon, line
export const getLayerStyles = _options => {
  const { mapColors } = COLORS
  const { active, white } = mapColors

  const themeColors = COLORS[getTheme()]
  const { iconsHighContrast } = themeColors

  const options = {
    type: '',
    color: iconsHighContrast,
    iconSize: [
      'interpolate',
      ['exponential', 1],
      ['zoom'],
      13,
      0.05,
      15,
      0.4,
      17,
      0.55,
      19,
      0.71
    ],
    circleRadius: [
      'interpolate',
      ['exponential', 1],
      ['zoom'],
      10,
      0.1,
      13,
      2,
      15,
      16,
      22,
      28
    ],
    icon: null,
    iconAllowOverlap: true,
    minzoom: 13,
    circleOpacity: ['step', ['zoom'], 1, 13, 0.25],
    polygonOpacity: 0.6,
    lineWidth: ['interpolate', ['linear'], ['zoom'], 13, 2, 18, 3], // 2
    lineOpacity: 1,
    iconOpacity: 1,
    additional: {},
    additionalSymbolLayout: {},
    additionalSymbolPaint: {},
    additionalCirclePaint: {},
    additionalPolygonPaint: {},
    additionalLineLayout: {},
    additionalLinePaint: {},
    ..._options
  }

  const fillStyle = {
    type: 'fill',
    paint: {
      'fill-opacity': options.polygonOpacity,
      'fill-color': options.color,
      ...options.additionalPolygonPaint
    },
    ...options.additional
  }

  const styles = {
    // Circle type
    circle: {
      type: 'circle',
      paint: {
        'circle-radius': options.circleRadius,
        'circle-color': options.color,
        'circle-opacity': options.circleOpacity,
        'circle-stroke-color': options.color,
        'circle-stroke-width': [
          'interpolate',
          ['linear'],
          ['zoom'],
          13,
          2,
          18,
          3
        ], // 2
        'circle-pitch-alignment': 'map',
        'circle-stroke-opacity': 1,
        ...options.additionalCirclePaint
      },
      ...options.additional
    },
    // Symbol type (icon/image)
    symbol: {
      type: 'symbol',
      minzoom: options.minzoom,
      layout: {
        'icon-image': options.icon,
        'icon-size': options.iconSize,
        'icon-allow-overlap': options.iconAllowOverlap,
        'icon-pitch-alignment': 'map',
        ...options.additionalSymbolLayout
      },
      paint: {
        'icon-color': options.color,
        'icon-opacity': options.iconOpacity,
        ...options.additionalSymbolPaint
      },
      ...options.additional
    },
    // Small point (used in fake layer)
    point: {
      type: 'circle',
      paint: {
        'circle-color': white,
        'circle-radius': 4,
        'circle-stroke-color': active,
        'circle-stroke-width': 2
      },
      ...options.additional
    },
    // Polygon styles
    // TODO: Bring to one view - fill
    polygon: fillStyle,
    fill: fillStyle,
    // Line styles
    line: {
      type: 'line',
      layout: {
        'line-cap': 'round',
        'line-join': 'round',
        ...options.additionalLineLayout
      },
      paint: {
        'line-opacity': options.lineOpacity,
        'line-color': options.color,
        'line-width': options.lineWidth,
        ...options.additionalLinePaint
      },
      ...options.additional
    },
    // Set active color when open card
    setActive({
      id,
      layerId,
      type,
      extended,
      color,
      conditionField = 'id',
      conditionOp = '=='
    }) {
      if (!_options.mapgl) {
        throw new Error(
          'You must pass the mapgl parameter in the layer options!'
        )
      }

      const mapgl = _options.mapgl

      if (!mapgl || mapgl._removed || !mapgl.getLayer(layerId)) return

      const propertyMap = {
        symbol: 'icon',
        circle: 'circle',
        polygon: 'fill', // TODO: Bring to one view - fill
        fill: 'fill',
        line: 'line'
      }

      const layoutProps = ['image', 'rotate', 'size']

      if (extended) {
        for (const prop in extended) {
          const propName = `${propertyMap[type]}-${prop}`
          const value = extended[prop] ?? null

          if (layoutProps.includes(prop)) {
            mapgl.setLayoutProperty(layerId, propName, value)
          } else {
            mapgl.setPaintProperty(layerId, propName, value)
          }
        }
      } else {
        const condition = [
          'case',
          [conditionOp, ['get', conditionField], id],
          color || active,
          options.color
        ]

        const propertyMap = {
          symbol: 'icon-color',
          circle: 'circle-color',
          polygon: 'fill-color', // TODO: Bring to one view - fill
          fill: 'fill-color',
          line: 'line-color'
        }

        mapgl.setPaintProperty(layerId, propertyMap[type], condition)

        if (type === 'circle') {
          mapgl.setPaintProperty(layerId, 'circle-stroke-color', condition)
        }
      }
    }
  }

  return options.type ? styles[options.type] : styles
}
